import { BrowserRouter as Router } from 'react-router-dom';
import ThirteenRouter from './routes';

function App() {
    return (
        <Router>
            <ThirteenRouter />
        </Router>
    );
}

export default App;