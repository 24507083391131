import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import { useNavigate } from 'react-router-dom';

import _, { set } from 'lodash';


import '../styles.css';

const toLocalTime = (time, tickMarkType, locale) => {
    const date = new Date(time * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    if (tickMarkType < 3) {
        return `${year}-${month}-${day}`;
    }
    return `${hour}:${minute}`;
};
const priceFormatMap = {
    "BNBUSDT": {
        minMove: 0.01,
        precision: 2,
    },
    "ETHUSDT": {
        minMove: 0.01,
        precision: 2,
    },
    "DOGEUSDT": {
        minMove: 0.00001,
        precision: 5,
    },
    "DOTUSDT": {
        minMove: 0.001,
        precision: 3,
    },
    "SOLUSDT": {
        minMove: 0.001,
        precision: 3,
    },
    "1000PEPEUSDT": {
        minMove: 0.0000001,
        precision: 7,
    },
    "ARBUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "CFXUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "LTCUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "OPUSDT": {
        minMove: 0.0000001,
        precision: 7,
    },
    "SUIUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "TRBUSDT": {
        minMove: 0.001,
        precision: 3,
    },
    "WLDUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "default": {
        minMove: 0.001,
        precision: 3,
    }
}

const Timezone = 60 * 60 * 9;

function Dalio() {
    // const symbols = ['BTCUSDT', 'ETHUSDT', 'SOLUSDT', 'BNBUSDT', '1000PEPEUSDT', 'DOGEUSDT', 'XRPUSDT', 'BOMEUSDT', '1000SHIBUSDT', 'AVAXUSDT', '1000FLOKIUSDT', 'WIFUSDT', 'NEARUSDT', 'ARBUSDT', 'RUNEUSDT'];
    // const symbols = ;
    const [symbols, setSymbols] = useState(['BTCUSDT', 'ETHUSDT', "SOLUSDT", "1000PEPEUSDT", "DOGEUSDT", "BNBUSDT"]);
    let symbol_dict = {};
    symbol_dict = symbols.reduce((acc, symbol, index) => {
        const keys = ['q', 'w', 'e', 'r', 't', 'a', 's', 'd', 'f', 'g', 'z', 'x', 'c', 'v', 'b'];
        acc[keys[index]] = symbol;
        return acc;
    }, {});

    const chartContainerRef = useRef();
    const inputRef = useRef();
    const chart = useRef();
    const resizeObserver = useRef();
    // key -> try 1. get parmeter from url, 2. symbols[0]
    const params = new URLSearchParams(window.location.search);
    const [key, setKey] = useState(params.get('key') || symbols[0]);
    const [autoRefresh, setAutoRefresh] = useState(null);
    const navigate = useNavigate();
    const apiURL = "https://dashboard-api.thirteen.ai";
    // const apiURL = "http://127.0.0.1:8000";


    var candleSeries, dqrSeries, profitSeries, usdtBarSeries, marginSeries;
    var last_time_range = null;
    const initChart = () => {
        // 차트 초기화
        // if chart.current is not null, remove it
        if (chart.current) chart.current.remove();

        // create chart
        chart.current = createChart(chartContainerRef.current, {
            overlayPriceScales: {
                scaleMargins: {
                    top: 0.6,
                    bottom: 0,
                }
            },
            rightPriceScale: {
                autoScale: true,
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.08,
                }
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                tickMarkFormatter: toLocalTime,
            },
            localization: {
                locale: 'ko-KR',
                dateFormat: 'yy-MM-dd',
            },

        });
        candleSeries = chart.current.addCandlestickSeries({
            title: '1m',
            priceFormat: priceFormatMap[key] || priceFormatMap["default"],
        });
        // usdtBarSeries = chart.current.addCandlestickSeries({
        //     title: 'usdtbar',
        //     priceFormat: priceFormatMap[key] || priceFormatMap["default"],
        //     pane: 1
        // });
        dqrSeries = chart.current.addHistogramSeries({
            title: 'dqr',
            priceFormat: {
                minMove: 0.001,
                precision: 3,
            },
            pane: 1
        });

        chart.current.timeScale().subscribeVisibleTimeRangeChange(_.debounce((timeRange) => {
            // 시간 범위 변경 이벤트가 1000ms간 발생하지 않을 때 호출됨
            if (last_time_range === timeRange.from) {
                console.log("Skip drawProfit", key, timeRange.from * 1000, timeRange.to * 1000, '15m');
                return;
            }
            // drawProfit(timeRange.from * 1000 - Timezone * 1000, timeRange.to * 1000 - Timezone * 1000);
            last_time_range = timeRange.from;

        }, 1000));
    };

    // Resize chart on container resizes.
    useEffect(() => {
        resizeObserver.current = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            chart.current.applyOptions({ width, height });
            chart.current.applyOptions(
                {
                    crosshair: {
                        // Change mode from default 'magnet' to 'normal'.
                        // Allows the crosshair to move freely without snapping to datapoints
                        mode: CrosshairMode.Normal,
                
                        // Vertical crosshair line (showing Date in Label)
                        vertLine: {
                            width: 8,
                            color: '#C3BCDB44',
                            style: LineStyle.Solid,
                            labelBackgroundColor: '#9B7DFF',
                        },
                
                        // Horizontal crosshair line (showing Price in Label)
                        horzLine: {
                            color: '#9B7DFF',
                            labelBackgroundColor: '#9B7DFF',
                        },
                    },
                }
            )
            setTimeout(() => {
                chart.current.timeScale().fitContent();
            }, 0);
        });

        resizeObserver.current.observe(chartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);

    const handleChange = (event) => {
        setKey(event.target.value);
        params.set('key', event.target.value);
        navigate(`?${params.toString()}`);
    };

    useEffect(() => {
        initChart();
        clearInterval(autoRefresh);
        loadChart(key);
        const newInterval = setInterval(() => {
            loadChart(key);
        }, 1000 * 60);

        setAutoRefresh(newInterval);
    }, [key]);

    const fetchHistoricalData = async (startTime, endTime, symbol, interval) => {
        const limit = 1000; // Maximum number of candles per request
        let chartData = [];

        while (startTime < endTime) {
            const response = await fetch(`https://fapi.binance.com/fapi/v1/klines?symbol=${symbol}&interval=${interval}&startTime=${startTime}&endTime=${endTime}&limit=${limit}`);
            const data = await response.json();
            if (data.length === 0) break; // No more data available
            chartData = [...chartData, ...data.map(item => ({
                time: item[0] / 1000,
                open: parseFloat(item[1]),
                high: parseFloat(item[2]),
                low: parseFloat(item[3]),
                close: parseFloat(item[4]),
            }))];
            startTime = data[data.length - 1][0] + 1; // Set start time to end time of last candle + 1
        }

        // fix timezone
        chartData = chartData.map((data) => {
            return {
                time: data.time,
                open: data.open,
                high: data.high,
                low: data.low,
                close: data.close,
            }
        });
        return chartData;
    };


    const fetchUSDTBarData = async (startTime, endTime, symbol) => {
        const limit = 1000; // Maximum number of candles per request
        let chartData = [];
        console.log(startTime, endTime);

        while (startTime < endTime) {
            // const response = await fetch(`https://dashboard-api.thirteen.ai/candles/usdtbar?symbol=${symbol}&start_time=${startTime}&end_time=${endTime}`);
            const response = await fetch(`${apiURL}/candles/usdtbar?symbol=${symbol}&start_time=${startTime}&end_time=${endTime}`);
            const data = await response.json();
            if (data.length === 0) break; // No more data available
            chartData = [...chartData, ...data.map(item => (
                {
                    time: new Date(item["timestamp"]).getTime() / 1000,
                    open: parseFloat(item["open"]),
                    high: parseFloat(item["high"]),
                    low: parseFloat(item["low"]),
                    close: parseFloat(item["close"]),
                }
            ))];
            startTime = data[data.length - 1][0] + 1; // Set start time to end time of last candle + 1
        }
        
        // fix timezone
        chartData = chartData.map((data) => {
            return {
                time: data.time + Timezone,
                open: data.open,
                high: data.high,
                low: data.low,
                close: data.close,
            }
        });

        return chartData;
    };

    const fetchDQR = async (symbol, st, ed) => {
        const response = await fetch(`${apiURL}/dqr/usdtbar?symbol=${symbol}&start_time=${st}&end_time=${ed}&v=&strategy=dalio`);
        const data = await response.json();
        const dqrData = data.map(item => ({
            // time: item.timestamp,
            time: new Date(item["datetime"]).getTime() / 1000 + Timezone,
            value: parseFloat(item.dqr ?? 0),
            color: item.dqr <= 0 ? '#E83B3F' : '#239888',
        }));
        return dqrData;
    };

    const fetchProfit = async (st, ed) => {
        const response = await fetch(`${apiURL}/portfolio/margin-balance?&start_time=${st}&end_time=${ed}&strategy=dalio`);
        const data = await response.json();
        const profitData = data.map(item => ({
            time: item.timestamp + Timezone,
            value: parseFloat(item.margin_balance),
        }));
        console.log(profitData)
        return profitData;
    };

    const drawProfit = async (st, ed) => {

        const profitData = await fetchProfit(st, ed);
        marginSeries.setData(profitData);
    };

    const loadChart = (symbol) => {
        var start_date, end_date, interval;
        interval = "1m";

        // Fetch initial chart data
        const fetchInitialData = async () => {
            end_date = Date.now() + 60 * 60 * 1000;  // 1 hour later
            start_date = Date.now() - 60 * 60 * 10 * 2 * 1000;  // 30 days ago

            // USDT Bar
            const chartData = await fetchUSDTBarData(start_date, end_date, symbol);
            // usdtBarSeries.setData(chartData);
            candleSeries.setData(chartData);

            // BTC 1m
            // const MarketData = await fetchHistoricalData(start_date, end_date, symbol, interval);
            // candleSeries.setData(MarketData);

            start_date = chartData[0].time - 1;
            end_date = chartData[chartData.length - 1].time + 1;

            const dqrData = await fetchDQR(symbol, start_date * 1000, end_date * 1000);
            dqrSeries.setData(dqrData);
        };

        fetchInitialData();
    }

    function handleKeyPress(event) {
        const key = event.key;
        if (symbol_dict.hasOwnProperty(key)) {
            setKey(symbol_dict[key]);
        }
        event.stopPropagation();
    }

    useEffect(() => {
        window.addEventListener('keyup', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div>
            <label style={{ marginRight: 10 }}>
                Current Symbol : {key}
            </label>
            <label>
                Session ID:
                <select value={key} onChange={handleChange} ref={inputRef}>
                    {symbols.map((symbol) => {
                        return (
                            <option key={symbol} value={symbol}>{symbol}</option>
                        )
                    })}
                </select>
            </label>
            <div className="LiveApp">
                <div ref={chartContainerRef} className="live-chart-container" />
            </div>
        </div>
    );


}

export default Dalio;
