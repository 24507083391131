import { Route, Routes } from 'react-router-dom';
import Analytics from './pages/Analytics';
import Backtest from './pages/Backtest';
import Live from './pages/Live';
import Simons from './pages/Simons';
import Dalio from './pages/Dalio';
import Lynch from './pages/Lynch';


function ThirteenRouter() {
  return (
    <Routes>
      <Route path="/simons" element={<Simons />} />
      <Route path="/lynch" element={<Lynch />} />
      <Route path="/dalio" element={<Dalio />} />
      <Route path="/" element={<Live />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/backtest" element={<Backtest />} />

    </Routes>
  );
}

export default ThirteenRouter;