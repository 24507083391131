// src/components/StageList/StageList.js
import React, { useState, useEffect, useRef } from 'react';
import './StageList.css';

const StageList = ({ stages, selectedStage, onSelectStage }) => {
    const stageListRef = useRef(null); // stageListRef 추가
    const selectedStageRef = useRef(null); // selectedStageRef 추가
    const [sortOrder, setSortOrder] = useState(null);
    const [sortBy, setSortBy] = useState('index');

    useEffect(() => {
        if (selectedStageRef.current && stageListRef.current) {
            const stageListRect = stageListRef.current.getBoundingClientRect();
            const selectedStageRect = selectedStageRef.current.getBoundingClientRect();

            if (selectedStageRect.top < stageListRect.top) {
                stageListRef.current.scrollTop -= 10 * (stageListRect.top - selectedStageRect.top);
            } else if (selectedStageRect.bottom > stageListRect.bottom) {
                stageListRef.current.scrollTop += 10 * (selectedStageRect.bottom - stageListRect.bottom);
            }
        }
    }, [selectedStage]);

    const handleSort = (sortKey) => {
        if (sortBy === sortKey) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(sortKey);
            setSortOrder('desc');
        }
        stageListRef.current.scrollTop = 0; 
    };

    const sortedStages = stages.sort((a, b) => {
        const valueA = sortBy === 'index' ? a.index : a.sharpe;
        const valueB = sortBy === 'index' ? b.index : b.sharpe;

        if (sortOrder === 'asc') {
            return valueA - valueB;
        }
        return valueB - valueA;
    });

    const sortButtonText = (sortKey) => {
        const baseText = sortKey === 'index' ? 'Sort by Time' : 'Sort by Sharpe';
        const arrow = sortOrder === 'asc' ? '👆' : '👇';
        return sortBy === sortKey ? `${baseText} ${arrow}` : baseText;
    };

    return (
        <div className="StageList">
            <div className="stage-list-controls">
                <button
                    onClick={() => handleSort('index')}
                    className={sortBy === 'index' ? 'active' : ''}
                >
                    {sortButtonText('index')}
                </button>
                <button
                    onClick={() => handleSort('sharpe')}
                    className={sortBy === 'sharpe' ? 'active' : ''}
                >
                    {sortButtonText('sharpe')}
                </button>
            </div>
            <div className="stage-list-items" ref={stageListRef}>
                <ul>
                    {sortedStages.map((stage, index) => (
                        <li
                            key={index}
                            className={selectedStage === stage ? 'selected' : ''}
                            onClick={() => onSelectStage(stage)}
                            ref={selectedStage === stage ? selectedStageRef : null}
                        >
                            {`#${stage.index + 1}: ${stage.repr_datetime} Sharpe: ${stage.sharpe}`}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default StageList;
