// src/App.js
import React, { useState, useEffect } from 'react';
import Chart from '../components/Chart/Chart';
import StageList from '../components/StageList/StageList';
import FloatingButton from '../components/FloatingButton/FloatingButton';
import SettingsModal from '../components/SettingsModal/SettingsModal';


import './Analytics.css';

const App = () => {
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const handleSettingsButtonClick = () => {
    setIsSettingsModalOpen(true);
  };

  const handleSettingsModalClose = () => {
    setIsSettingsModalOpen(false);
  };

  const handleSettingsSubmit = (settings) => {
    const { checkpointPath, symbol } = settings;
    alert(`checkpointPath: ${checkpointPath}, symbol: ${symbol}`);
    setIsSettingsModalOpen(false);
  };


  useEffect(() => {
    const loadStages = async () => {
      const response = await fetch('/BNBUSDT.json');
      const data = await response.json();
      setStages(data);
      setSelectedStage(data[0]);
    };
    loadStages();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault(); // 추가된 코드
        const currentIndex = stages.findIndex((stage) => stage === selectedStage);
        if (e.key === 'ArrowUp' && currentIndex > 0) {
          setSelectedStage(stages[currentIndex - 1]);
        } else if (e.key === 'ArrowDown' && currentIndex < stages.length - 1) {
          setSelectedStage(stages[currentIndex + 1]);
        }
      };
      //  else if (e.key === 'Escape') {
      //   setIsSettingsModalOpen(true);
      // };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [stages, selectedStage]);

  const handleSelectStage = (stage) => {
    setSelectedStage(stage);
  };

  return (
    <div className="App">
      <div className="chart-container">
        {selectedStage && <Chart stage={selectedStage} />}
      </div>
      <div className="stage-list-container">
        <StageList stages={stages} selectedStage={selectedStage} onSelectStage={handleSelectStage} />
      </div>
      <FloatingButton onClick={handleSettingsButtonClick} />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        onClose={handleSettingsModalClose}
        onSubmit={handleSettingsSubmit}
      />
    </div>
  );
};

export default App;