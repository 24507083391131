import React, { useEffect, useState, useRef } from 'react';
import './SettingsModal.css';

const SettingsModal = ({ isOpen, onClose, onSubmit }) => {
  const [checkpointPath, setCheckpointPath] = useState('');
  const [symbol, setSymbol] = useState(''); // Symbol state 추가
  const modelCheckpointPathRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      modelCheckpointPathRef.current.focus();

      const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit({ checkpointPath, symbol }); // symbol 추가
  };
  const handleInputKeyDown = (event) => { // input에서의 keydown 이벤트 처리
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="SettingsModal">
      <div className="modal-content">
        <h2>Settings</h2>
        <label>
          Model Checkpoint Path:
          <input
            type="text"
            value={checkpointPath}
            onChange={(e) => setCheckpointPath(e.target.value)}
            onKeyDown={handleInputKeyDown}
            ref={modelCheckpointPathRef}
          />
        </label>
        <label>
          Symbol: {/* Symbol 입력란 추가 */}
          <input
            type="text"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
            onKeyDown={handleInputKeyDown}
          />
        </label>
        <div className="buttons">
          <button className="cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
