import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createChart, CrosshairMode } from 'lightweight-charts';

import { fetchHistoricalData } from '../apis/HistoricalData';

import './Backtest.css';

const toLocalTime = (time, tickMarkType, locale) => {
    const date = new Date(time * 1000);
    return date.toLocaleString(locale);
};

function Backtest() {
    const chartContainerRef = useRef();
    const inputRef = useRef();
    const chart = useRef();
    const resizeObserver = useRef();
    const [key, setKey] = useState("");

    const initChart = () => {
        // 차트 초기화        
        chart.current = createChart(chartContainerRef.current, {
            overlayPriceScales: {
                scaleMargins: {
                    top: 0.6,
                    bottom: 0,
                }
            },
            rightPriceScale: {
                autoScale: true,
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.08,
                }
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
            },
        });
    };

    useEffect(() => {
        initChart();
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    // Resize chart on container resizes.
    useEffect(() => {
        resizeObserver.current = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            chart.current.applyOptions({ width, height });
            setTimeout(() => {
                chart.current.timeScale().fitContent();
            }, 0);
        });

        resizeObserver.current.observe(chartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);

    const handleChange = (event) => {
        setKey(event.target.value);
    };

    const handleSubmit = (event) => {

        chart.current.remove();
        initChart();

        const candleSeries = chart.current.addCandlestickSeries({
            title: 'primary',
            priceFormat: {
                minMove: 0.001,
                precision: 3,
            }
        });
        const lineSeries = chart.current.addLineSeries({ lineWidth: 1 });
        const dqrSeries = chart.current.addHistogramSeries({
            title: 'dqr',
            priceFormat: {
                minMove: 0.01,
                precision: 3,
            },
            pane: 1
        });
        const profitSeries = chart.current.addLineSeries({
            title: 'profit',
            priceFormat: {
                minMove: 0.01,
                precision: 3,
            },
            lineWidth: 1,
            color: '#00ff00',
            pane: 2
        })
        const pctSeries = chart.current.addLineSeries({
            title: 'close_pct',
            priceFormat: {
                minMove: 0.01,
                precision: 3,
            },
            lineWidth: 1,
            color: '#2045FF',
            pane: 2
        })
        const pnlSeries = chart.current.addLineSeries({
            title: 'pnl',
            priceFormat: {
                minMove: 0.01,
                precision: 3,
            },
            lineWidth: 1,
            color: '#00ff00',
            pane: 3
        })
        var symbol, start_date, end_date;
        fetch(process.env.REACT_APP_API_URL + `/histories?key=${key}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY
            },
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dqrSeries.setData(data.dqr);
                profitSeries.setData(data.profit);
                lineSeries.setData(data.entry_price);
                pnlSeries.setData(data.pnl);
                symbol = data.symbol;
                start_date = data.start_date;
                end_date = data.end_date;

                const startTimestamp = new Date(start_date).getTime();
                const endTimestamp = new Date(end_date).getTime();

                fetchHistoricalData(startTimestamp, endTimestamp, symbol, '30m').then(
                    chartData => {
                        candleSeries.setData(chartData);
                    });

                // fetch(process.env.REACT_APP_API_URL + `/query_candles?symbol=${symbol}&start_time=${start_date}&end_time=${end_date}&interval=30m`, {
                //     method: 'GET',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'x-api-key': process.env.REACT_APP_API_KEY
                //     },
                // })
                //     .then(res => res.json())
                //     .then(data => {
                //         const cdata = data.map(d => {
                //             return { time: d[0] / 1000, open: parseFloat(d[1]), high: parseFloat(d[2]), low: parseFloat(d[3]), close: parseFloat(d[4]) }
                //         });
                //         candleSeries.setData(cdata);
                //         const pct = data.map(d => {
                //             return { time: d[0] / 1000, value: parseFloat(d[5]) }
                //         });
                //         pctSeries.setData(pct);
                //     })
                //     .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
        event.preventDefault();
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <label>
                    Name :
                    <input type="text" value={key} onChange={handleChange} ref={inputRef} />
                </label>
            </form>
            <div className="App">
                <div ref={chartContainerRef} className="chart-container" />
            </div>
        </>
    );


}

export default Backtest;
