import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import './Chart.css';

const toLocalTime = (time, tickMarkType, locale) => {
    const date = new Date(time * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    if (tickMarkType < 3) {
        return `${year}-${month}-${day}`;
    }
    return `${hour}:${minute}`;
};

const fetchHistoricalData = async (startTime, endTime, symbol, interval) => {
    const limit = 1000; // Maximum number of candles per request
    let chartData = [];

    while (startTime < endTime) {
        const response = await fetch(`https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${interval}&startTime=${startTime}&endTime=${endTime}&limit=${limit}`);
        const data = await response.json();
        if (data.length === 0) break; // No more data available
        chartData = [...chartData, ...data.map(item => ({
            time: item[0] / 1000,
            open: parseFloat(item[1]),
            high: parseFloat(item[2]),
            low: parseFloat(item[3]),
            close: parseFloat(item[4]),
        }))];
        startTime = data[data.length - 1][0] + 1; // Set start time to end time of last candle + 1
    }

    return chartData;
};

const Chart = ({ stage }) => {
    const chartContainerRef = useRef();
    const chart = useRef();
    const resizeObserver = useRef();
    const [key, setKey] = useState("BNBUSDT");
    var candleSeries, dqrSeries, profitSeries;

    // Create chart
    const initChart = () => {
        // 차트 초기화
        if (chart.current) chart.current.remove();

        // create chart
        chart.current = createChart(chartContainerRef.current, {
            overlayPriceScales: {
                scaleMargins: {
                    top: 0.6,
                    bottom: 0,
                }
            },
            rightPriceScale: {
                autoScale: true,
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.08,
                }
            },
        });
        candleSeries = chart.current.addCandlestickSeries({
            title: 'close',
            priceFormat: {
                minMove: 0.01,
                precision: 2,
            },
        });
        dqrSeries = chart.current.addHistogramSeries({
            title: 'dqr',
            priceFormat: {
                minMove: 0.01,
                precision: 3,
            },
            pane: 1
        });
        profitSeries = chart.current.addLineSeries({
            title: 'live',
            priceFormat: {
                minMove: 0.01,
                precision: 5,
            },
            lineWidth: 1,
            color: '#63B556',
            pane: 2
        });
    };

    // Load chart data
    const loadChart = (symbol) => {
        const start_date = stage.repr_timestamp - 30 * 60 * 256 * 1000;
        const end_date = stage.repr_timestamp + 30 * 60 * 30 * 1000 - 1;
        const interval = "30m";

        // Fetch initial chart data
        const fetchInitialData = async () => {
            const chartData = await fetchHistoricalData(start_date, end_date, symbol, interval);

            candleSeries.setData(chartData);
        };

        fetchInitialData();

        const dqrData = stage.timestamps.map((timestamp, index) => ({
            time: stage.timestamps[index] / 1000,
            value: stage.quantities[index],
            color: stage.quantities[index] < 0 ? '#E83B3F' : '#239888'
        }));

        const profitData = stage.timestamps.map((timestamp, index) => ({
            time: stage.timestamps[index] / 1000,
            value: stage.cum_profit[index]
        }));

        dqrSeries.setData(dqrData);
        profitSeries.setData(profitData);




    };

    useEffect(() => {
        initChart();
        loadChart("BNBUSDT");
    }, [stage]);

    // Resize chart on container resizes.
    useEffect(() => {
        resizeObserver.current = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            chart.current.applyOptions({ width, height });
            setTimeout(() => {
                chart.current.timeScale().fitContent();
            }, 0);
        });

        resizeObserver.current.observe(chartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);

    return (
        <div className="chart-container-app" ref={chartContainerRef} />
    );
};

export default Chart;
