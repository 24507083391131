export const fetchHistoricalData = async (startTime, endTime, symbol, interval) => {
    const limit = 1000; // Maximum number of candles per request
    let chartData = [];

    console.log("Called fetchHistoricalData", startTime, endTime, symbol, interval);

    while (startTime < endTime) {
        const response = await fetch(`https://fapi.binance.com/fapi/v1/klines?symbol=${symbol}&interval=${interval}&startTime=${startTime}&endTime=${endTime}&limit=${limit}`);
        const data = await response.json();
        if (data.length === 0) break; // No more data available
        chartData = [...chartData, ...data.map(item => ({
            time: item[0] / 1000,
            open: parseFloat(item[1]),
            high: parseFloat(item[2]),
            low: parseFloat(item[3]),
            close: parseFloat(item[4]),
        }))];
        startTime = data[data.length - 1][0] + 1; // Set start time to end time of last candle + 1
    }

    return chartData;
};