import React from 'react';
import './FloatingButton.css';

const FloatingButton = ({ onClick }) => {
  return (
    <button className="FloatingButton" onClick={onClick}>
      <i className="fas fa-cog"></i>
    </button>
  );
};

export default FloatingButton;
